&-destination {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: $border-radius-lg *3;
  margin: $font-size-base*.8 auto;

  .card-icon {
    cursor: pointer;

    &-full {
      position: relative;
      overflow: hidden;
      // max-height: 200px;
    }

    img {
      @include transition(all 0.3s ease);
      display: block;
      width: 100%;
      // filter: grayscale(1);
    }
  }

  .title-text {
    // position: absolute;
    // z-index: 3;
    // left: 0;
    // right: 0;
    // bottom: 0;
    //
    
    
    line-height: 1;
    @include gradient-y-three-colors($start-color: rgba($primary, .9), $mid-color: rgba($primary, .9), $color-stop: 50%, $end-color: rgba($primary, .9));
    padding: $font-size-base $font-size-base;

    .title {
      color: $white;
      font-size: 20px;
      margin-bottom: $font-size-base * 1.2;
    }

    .call-now {

      .bold {
        display: block;
      }
    }

    .days {
      font-weight: 700;
      font-size: 80%;
      margin-bottom: $font-size-base * .3;
      color: $orange-400;
    }

    .text {
      margin: 0;
      @include text-ellipsis();
      font-weight: 800;
      min-height: $font-size-base * 3.8;
      // white-space: nowrap;
      -webkit-box-flex: 2;
      flex-grow: 2;
      flex: 1;
    }


    .point {
      position: relative;
      z-index: 0;
      padding: $font-size-base 0;

      &::after {
        position: absolute;
        content: "";
        z-index: -1;
        background-color: rgba($black, .4);
        top: 0;
        bottom: 0;
        left: -$font-size-base;
        right: -$font-size-base;
      }

      color: $white;
      font-size: 90%;

      .title {
        font-weight: 700;
        font-size: 90%;
        margin-bottom: $font-size-base * .5;
        color: $orange-400;
      }

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        list-style: none;
        position: relative;
        margin-bottom: $font-size-base*.4;
        margin-top: $font-size-base*.4;
        padding-left: $font-size-base*1.6;

        &:after {
          font-family: $font-awesome-free;
          // font-weight: 600;
          content: "\f058";
          position: absolute;
          color: $green-300;
          top: $font-size-base*.1;
          left: 0;
          width: $font-size-base*.5 ;
          height: $font-size-base*.5 ;
        }
      }

    }
  }

  .book-now {
    margin-top: $font-size-base;
    color: $white;
    font-size: 20px;

    .price-in {
      padding-left: $font-size-base*.6;
    }

    .icons {
      font-size: 180%;
      margin-right: $font-size-base * 0.2;
    }

    .rstag {
      font-size: 80%;

    }

    .ammount {
      font-size: 120%;
      font-weight: 600;
      margin-bottom: $font-size-base * 0.2;
      .small{
        font-size: 60%;
      }
    }

    .person {
      font-size: 80%;
      margin-bottom: $font-size-base * 0.3;
    }

    &-tag {
      position: absolute;
      top: $font-size-base*1.5;
      right: 0;
      z-index: 3;

      .price {
        background: rgba($black, .4);
        color: $white;
        padding: $font-size-base*.5 $font-size-base;
        font-weight: 600;
      }
    }
  }

  &:hover {
    .card-icon {
      img {
        @include transition(all 0.3s ease);
        filter: grayscale(0);
      }
    }
  }

  &-slider {

    .swiper-slide-active,
    .swiper-slide:hover {
      img {
        @include transition(all 0.3s ease);
        display: block;
        width: 100%;
        filter: grayscale(0);
      }
    }
  }
}