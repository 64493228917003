&-avatar {
	// display: block;
	display: flex;
	align-items: center;
	padding-bottom: 0;
	margin: $font-size-base * 0.1 auto;
	border-radius: $border-radius-sm;
	// background-color: rgb(44, 84, 136);

	&.with-border {
		border: 1px solid rgba($border-colors, 0.5);
		margin: 0 auto;
		margin-bottom: $font-size-base * 2;
		border-radius: $border-radius-lg;
		padding: $font-size-base * 0.8 $font-size-base * 1.2;
	}
	&.with-close {
		margin: 0 auto;
		margin-bottom: $font-size-base * 3;
	}
	&.with-margin {
		border: 1px solid rgba($border-colors, 0.5);
		margin: 0 auto;
		margin-bottom: $font-size-base;
		border-radius: $border-radius-lg;
		padding: $font-size-base * 0.8 $font-size-base * 1.2;
		&:hover,
		.active {
			border-color: $primary;
			.card-text {
				color: $primary;
			}
		}
	}
	.card-icon {
		color: $dark;
		// display: inline-block;
		// max-width: $font-size-base * 3;
		// min-width: calc(0.95 / 4 * 100%);
		min-width: $font-size-base * 3;
		max-width: $font-size-base * 3;
		margin-right: $font-size-base * 1.2;

		&-xs {
			min-width: $font-size-base * 3;
			max-width: $font-size-base * 3;
		}
		&-sm {
			min-width: $font-size-base * 4;
			max-width: $font-size-base * 4;
		}
		&-md {
			min-width: $font-size-base * 6;
			max-width: $font-size-base * 6;
		}
		&-xl {
			min-width: $font-size-base * 8;
			max-width: $font-size-base * 8;
		}

		&.with-icons {
			// display: flex;
		}
	}
	.card-content {
		color: $dark;
		// display: inline-block;
		max-width: $font-size-base * 9;
		padding-left: $font-size-base * 0.5;
		&.with-icons {
			// display: flex;
		}
		.btns {
			border-radius: $font-size-base;
			padding-left: $font-size-base * 1.2;
			padding-right: $font-size-base * 1.2;
		}
	}
	.image {
		display: block;
		overflow: hidden;
		position: relative;
		z-index: 3;
		.num {
			position: absolute;
			z-index: 2;
			right: $font-size-base * 0.4;
			bottom: $font-size-base * 0.7;
			font-size: $font-size-base * 1.1;
			// 
			// 
			line-height: 0.8;
			font-weight: 900;
			letter-spacing: -1px;
		}
		&-xs {
			width: $font-size-base * 3;
			height: $font-size-base * 3;
		}
		&-sm {
			width: $font-size-base * 4;
			height: $font-size-base * 4;
		}
		&-md {
			width: $font-size-base * 6;
			height: $font-size-base * 6;
		}
		&-xl {
			width: $font-size-base * 8;
			height: $font-size-base * 8;
		}
		img {
			display: block;
			width: 100%;
		}
		svg,.svg {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.figure {
		display: block;
		overflow: hidden;
		position: relative;
		z-index: 3;
		&-xs {
			// width: $font-size-base * 3;
			// height: $font-size-base * 3;
		}
		&-sm {
			width: $font-size-base * 4;
			height: $font-size-base * 4;
		}
		&-md {
			width: $font-size-base * 6;
			height: $font-size-base * 6;
		}
		&-xl {
			width: $font-size-base * 8;
			height: $font-size-base * 8;
		}
		img {
			display: block;
			width: 100%;
		}
	}

	&-fig {
		.image {
			display: block;
			overflow: hidden;
			position: relative;
			z-index: 3;
			margin-bottom: $font-size-base * 2.5;
			.num {
				position: absolute;
				z-index: 2;
				right: $font-size-base * 0.4;
				bottom: $font-size-base * 0.7;
				font-size: $font-size-base * 1.1;
				
				
				line-height: 0.8;
				font-weight: 900;
				letter-spacing: -1px;
			}
			&-xs {
				width: $font-size-base * 3;
				height: $font-size-base * 3;
			}
			&-sm {
				width: $font-size-base * 4;
				height: $font-size-base * 4;
			}
			&-md {
				width: $font-size-base * 6;
				height: $font-size-base * 6;
			}
			&-xl {
				width: $font-size-base * 8;
				height: $font-size-base * 8;
			}
			img {
				display: block;
				width: 100%;
			}
		}
		min-height: 415px;
		border: 1px solid rgba($border-colors, 0.5);
		margin: 0 auto;
		// margin-bottom: $font-size-base * 2;
		border-radius: $border-radius-lg;
		padding: $font-size-base * 1.8;
		background-color: #ccf3f9;
		&-nav {
			margin-bottom: $font-size-base * 2;
		}
		.title {
			&-meta {
				margin-bottom: $font-size-base * 1.2;
			}
			&-text {
				font-size: $font-size-base * 1.15;
				font-weight: 700;
				line-height: 1.3;
			}
			&-content {
				margin: 0;
				
				font-size: $font-size-base * 0.9;
				font-weight: 400;
			}
		}
	}

	// .card-text {
	// 	// color: $dark;
	// 	&.with-table {
	// 		// min-width: calc(3.15 / 4 * 100%);
	// 		// max-width: calc(3.15 / 4 * 100%);
	// 	}
	// 	.badge {
	// 		margin-left: $font-size-base * 0.8;
	// 		font-size: $font-size-base * 0.75;
	// 	}
	// 	.title {
	// 		font-size: $font-size-base * 1.55;
	// 		font-weight: 600;
	// 	}
	// 	.header {
	// 		margin: 0;
	// 		font-size: $font-size-base * 1.35;
	// 		font-weight: 600;
	// 	}
	// 	.title-text {
	// 		font-size: $font-size-base * 1.2;
	// 		// line-height: 1.2;
	// 		font-weight: 600;
	// 	}
	// 	.heading {
	// 		font-size: $font-size-base * 1.55;
	// 		font-weight: 800;
	// 	}
	// 	.designation {
	// 		font-size: $font-size-base * 1.1;
	// 		font-weight: 800;
	// 		line-height: 1.1;

	// 	}
	// 	.name {
	// 		@include text-ellipsis();
	// 		font-size: $font-size-base * 1.9;
	// 		// font-weight: 800;
	// 		// line-height: 1.2;
	// 		margin-bottom: $font-size-base * 0.5;
	// 		@include media-breakpoint-down(sm) {
	// 			font-size: $font-size-base * 1.3;
	// 		}
	// 	}
	// 	.address {
	// 		font-size: $font-size-base;
	// 		// font-weight: 800;
	// 	}
	// 	.sub-title {
	// 		font-size: $font-size-base * 2;
	// 	}
	// 	.title-content {
	// 		@include text-ellipsis();
	// 		// -webkit-box-orient: vertical;
	// 		// -webkit-line-clamp: 3;
	// 		// height: $font-size-base * 4;
	// 		font-size: $font-size-base * 0.9;
	// 		margin-bottom: $font-size-base * 1.6;
			
	// 		p {
	// 			margin: 0;
	// 		}
	// 		&.auto {
	// 			-webkit-line-clamp: initial;
	// 			height: auto;
	// 			margin: 0;
	// 			p {
	// 				margin: 0;
	// 			}
	// 		}
	// 	}
	// 	.mini-title {
	// 		font-size: $font-size-base * 0.9;
			
	// 	}
	// 	.title-qus {
	// 		font-size: $font-size-base * 1.2;
	// 		line-height: 1.2;
	// 		font-weight: 600;
	// 		// 
	// 	}
	// 	.title-email {
	// 		font-size: $font-size-base * 0.9;
			
			
	// 	}
	// 	.title-date {
	// 		font-size: $font-size-base * 0.9;
			
			
	// 		margin-right: $font-size-base * 0.9;
	// 	}
	// }
	.meta-header {
		margin-bottom: $font-size-base * 0.8;
	}
	.meta-tags {
		margin-bottom: $font-size-base * 1.2;
	}
	.card-badge {
		padding: $font-size-base * 0.2 $font-size-base * 0.5;
		color: $dark;
		.title {
			font-size: $font-size-base * 1.55;
			font-weight: 600;
		}
		.heading {
			font-size: $font-size-base * 1.55;
			font-weight: 700;
		}
		.sub-heading {
			font-size: $font-size-base * 1.55;
			// font-weight: 700;
		}
		.sub-title {
			font-size: $font-size-base * 2;
		}
		.mini-title {
			font-size: $font-size-base * 0.8;
			// 
		}
		.title-email {
			font-size: $font-size-base * 0.9;
			
			
		}
	}
}
