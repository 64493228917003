.signup-wrapper {
	padding: $font-size-base * 5 0;
	@include media-breakpoint-up(sm) {
		padding: $font-size-base * 5 0;
	}
}

.page-signup-card {
	background: url('../images/page-body-bg.png') top -20px right -20px no-repeat;
	background-size: contain;
	@include media-breakpoint-up(sm) {
		background: url('../images/page-body-bg.png') top right no-repeat;
		background-size: auto 100%;
		background-attachment: fixed;
	}
}

.signup-form {
	&.mini-card {
		max-width: initial;
	}

	&.large-card {
		max-width: initial;
	}
	.form-info {
		padding: $font-size-base 0;
		text-align: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
		.bold {
			margin: 0 10px;
		}
		.disable {
			@include opacity(0.5);
			pointer-events: none;
			cursor: not-allowed;
		}
	}
	@include media-breakpoint-up(sm) {
		&.mini-card {
			max-width: $modal-sm + 40;
		}
		&.large-card {
			max-width: $modal-sm * 1.3;
		}
	}
	.d-grid {
		margin-top: $font-size-base * 2;
	}
}

.list-otp-input {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	.text-control {
		width: 15%;
		height: $font-size-base * 3.5;
		padding-left: 0;
		padding-right: 0;
		max-width: 50px;
		border-radius: 5px;
		text-align: center;
		font-size: 28px;
		line-height: 100%;
		color: #1e7ae0;
		border: #bcd5f7 1px solid;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		-moz-appearance: textfield;

		@include tab-focus();

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}

.block-steps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 1;
	max-width: $modal-sm + 40;
	margin-bottom: $font-size-base * 5;
	// margin-top: $font-size-base * 2;

	&.large {
		max-width: initial;
	}

	@include media-breakpoint-up(sm) {
		margin-bottom: $font-size-base * 3;
		&.large {
			max-width: 60%;
		}
	}

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		height: 1px;
		background: #ecf7fc;
		z-index: -1;
	}

	.card-title {
		font-size: 12px;
		color: $blue_color;
		// font-family: $font_maison;
		background: #fff;
		padding-right: 10px;
		margin: 0;
	}

	.card-number {
		font-weight: bold;
		font-size: 13px;
		color: #bbd7f6;
		width: 34px;
		height: 34px;
		border-radius: 50%;
		background: #f4f4f4;
		border: 1px solid lighten($primary, 35%);
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			color: $blue_color;
		}
	}
}

.form{
	&-steps {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 1;
		max-width: 420px;
		margin-bottom: $font-size-base * 4;

		&::before {
			content: "";
			position: absolute;
			width: 100%;
			left: 0;
			top: 50%;
			height: 1px;
			background: #ecf7fc;
			z-index: -1;
		}

		&-title {
			font-size: $font-size-base * .90;
			// color: $primary;
			// font-family: $font-family-anybody; 
			background: #fff;
			padding-right: 10px;
		}

		&-number {
			font-weight: bold;
			font-size: 13px;
			color: #bbd7f6;
			width: 34px;
			height: 34px;
			border-radius: 50%;
			background: #f9fafb;
			border: #ecf7fc 1px solid;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				color: $primary;
			}
		}
	}
}
