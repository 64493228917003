&-slider {
	&-base { 
		&-prev,
		&-next {
			cursor: pointer;
			.title-icons {
				img,
				.svg,
				svg {
					display: block;
					height: $font-size-base*1.1;
				}
			}

			padding: $font-size-base * 0.5 $font-size-base;
			background-color: rgba($primary, 0.15);
			border-radius: $border-radius;

			&:hover {
				background-color: $primary;
 
				svg, 
				.svg {
					path {
						fill: $white;
					}

					fill: $white;
				}
			}

			&.swiper-button-disabled {
				cursor: auto;
				pointer-events: none;
				@include opacity(0.7);
			}
		}

		&-next {
			margin-left: $font-size-base * 0.5;
		}
	}
}
