&-slider {
	&-prev,
	&-next {
    cursor: pointer;
		.title-icons {
			img,
			.svg,
			svg {
				display: block;
				height: $font-size-base*1.2;
			}
		}

		padding: $font-size-base * 0.15 $font-size-base * 0.4;
		background-color: rgba($primary, 0.15); 
		border-radius: $border-radius;

		&:hover {
			background-color: $primary;
			svg,
			.svg {
				path {
					fill: $primary;
				}

				fill: $primary;
			}
		}
    
		&.swiper-button-disabled {
			cursor: auto;
			pointer-events: none;
			@include opacity(0.7);
		}
	}

	&-next {
		margin-left: $font-size-base * 0.5;
    &:hover {
			background-color: $primary;
			svg,
			.svg {
				path {
					fill: $primary;
				}

				fill: $primary;
			}
		}
	}
}
