&-office {
	&-address {
		// margin: 50px auto;
		display: block;
		position: relative;
		padding-left: 0; 
		margin-bottom: $font-size-base * 2.5;

@include media-breakpoint-up(lg) {padding-left: 140px; }
@include media-breakpoint-up(xl) {padding-left: 140px; }
@include media-breakpoint-up(xxl){padding-left: 140px; }

		&-img {
			display: none;
			img {
				display: block;
				width: $font-size-base * 5;
				margin: 0 auto;
				position: absolute;
				left: 0;
				top: 50%;
				width: $font-size-base * 5;
				top: $font-size-base * 5;
				left: 15px;
				margin-top: -$font-size-base * 2.5;
			}
@include media-breakpoint-up(lg) {display: block;}
@include media-breakpoint-up(xl) {display: block;}
@include media-breakpoint-up(xxl){display: block;}
		}

		&-content {
			border-left: 2px solid $primary;
			padding-left: $font-size-base * 1.5;
			font-size: 90%;
@include media-breakpoint-up(lg) {padding-left: $font-size-base * 3;}
@include media-breakpoint-up(xl) {padding-left: $font-size-base * 3;}
@include media-breakpoint-up(xxl){padding-left: $font-size-base * 3;}
		}

		a {
			text-decoration: none;
			color: #333333;

			&:hover {
				color: $primary;
			}
		}

		.icons {
			margin-right: 10px;
		}

		.header {
			font-size: 140%;
		}

		&-quote-form{
			// padding: $font-size-base * 2 0;
		}
	}
}
