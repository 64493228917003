&-rent-car {
  position: relative;
  z-index: 2;

  &-img {
    margin-top: $font-size-base*3;

    img {
      display: block;
      width: 100%;
      object-fit: fill;
      border-radius: $border-radius*2;
    }
  }

  .heading {
    // line-height: 1.1;
    // font-weight: 600;
    margin-bottom: $font-size-base * 1.5;


    .text {
      display: inline-block;
      background-image: url("../images/line.svg");
      background-position: 80% 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-attachment: scroll;
      -webkit-transition-property: all;
      transition-property: all;
    }

    span,
    .span {
      font-weight: 700;
    }
  }

  &-content {

    &-outer {
      margin-top: $font-size-base*3;
      margin-left: 0;

      @include media-breakpoint-up(lg) {margin-left: $font-size-base*2;}
      @include media-breakpoint-up(xl) {margin-left: $font-size-base*2;}
      @include media-breakpoint-up(xxl){margin-left: $font-size-base*2;}

      &.even {
        margin-left: 0;
        margin-right: 0;
        @include media-breakpoint-up(lg) {margin-right: $font-size-base*2;}
        @include media-breakpoint-up(xl) {margin-right: $font-size-base*2;}
        @include media-breakpoint-up(xxl){margin-right: $font-size-base*2;}
      }
    }

    ul {
      margin: 0;
      padding: 0;
      margin-left: -$font-size-base * .15;
      margin-bottom: $font-size-base;

      li {
        list-style: none;
        font-size: 85%;
        font-weight: 600;
        display: inline-block;
        padding: $font-size-base * .3 $font-size-base *.6;
        margin: $font-size-base * .3;
        border: 1px solid $primary;
        border-radius: $border-radius;
      }
    }
  }


  @include media-breakpoint-up(sm) {
    min-height: 100vh;

  }

  @include media-breakpoint-up(md) {
    min-height: 100vh;

  }

  @include media-breakpoint-up(lg) {
    min-height: 100vh;

  }

  @include media-breakpoint-up(xl) {
    min-height: 100vh;

  }

  @include media-breakpoint-up(xxl) {
    min-height: 100vh;

  }
}
