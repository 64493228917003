&-success {
	&-stories { 
		&-prev,
		&-next {
			.title-icons {
				img,
				.svg,
				svg {
					display: block;
					height: $font-size-base*1.1;
				}
			}

			padding: $font-size-base * 0.5 $font-size-base;
			background-color: rgba($primary, 0.15);
			border-radius: $border-radius;

			&:hover {
				background-color: $primary;

				svg,
				.svg {
					path {
						fill: $white;
					}

					fill: $white;
				}
			}

			&.swiper-button-disabled {
				cursor: auto;
				pointer-events: none;
				@include opacity(0.7);
			}
		}

		&-next {
			margin-left: $font-size-base * 0.5;
		}
		&-text{
			border: 2px solid $border-color;
			padding: $font-size-base * 2;
			border-radius: $border-radius*5;

			.title-stars {
				margin-bottom: $font-size-base * 1.2;
				font-size: 80%;
			}
			.comment {
				margin-bottom: $font-size-base * 2.5;
				// font-family: $font-family-nunito;
				font-size: 80%;
				min-height: 50vh;
			@include media-breakpoint-up(lg) {font-size: 120%; min-height: 25vh;}
			@include media-breakpoint-up(xl) {font-size: 120%; min-height: 25vh;}
			@include media-breakpoint-up(xxl){font-size: 120%; min-height: 25vh;}
			}
			.designation {
				font-weight: 600;
				line-height: 1;
			}
			.address {
				font-size: 80%;
				color: $gray-600;
			}
		}
	}
}
