.full-width {
  display: block;
  width: 100% !important;

  &-round {
    border-radius: $border-radius-lg * 3;
  }
}

.section-padding {
  padding: 0;
@include media-breakpoint-up(sm) {padding: $font-size-base * 3 0; }
@include media-breakpoint-up(md) {padding: $font-size-base * 3 0; }
@include media-breakpoint-up(lg) {padding: $font-size-base * 3 0; }
@include media-breakpoint-up(xl) {padding: $font-size-base * 3 0; }
@include media-breakpoint-up(xxl){padding: $font-size-base * 3 0; }
}

.section {
  &-half-width {
    min-height: 85vh;
    padding: $font-size-base * 4 0;

    &.no-padding {
      min-height: 55vh;
      padding: $font-size-base * 2 0;
    }
    .section-img{ margin-bottom: $font-size-base * 2; }

    &-content {
      padding: $font-size-base $font-size-base * 5;

      .title {
        font-size: $font-size-base;
        margin-bottom: $font-size-base * 1.5;
        line-height: 1.1;
        font-weight: 700;
      }

      .sub-title {
        margin-bottom: $font-size-base * 0.5;
        font-size: $font-size-base;
        // font-weight: 300;
        color: $gray-700;
      }
    }
    @include media-breakpoint-up(sm) { .section-img{ margin-bottom: 0; } .title {font-size: $font-size-base * 1.6;} .sub-title {font-size: $font-size-base * 1.2;} }
    @include media-breakpoint-up(md) { .section-img{ margin-bottom: 0; } .title {font-size: $font-size-base * 1.6;} .sub-title {font-size: $font-size-base * 1.2;} }
    @include media-breakpoint-up(lg) { .section-img{ margin-bottom: 0; } .title {font-size: $font-size-base * 1.6;} .sub-title {font-size: $font-size-base * 1.2;} }
    @include media-breakpoint-up(xl) { .section-img{ margin-bottom: 0; } .title {font-size: $font-size-base * 1.6;} .sub-title {font-size: $font-size-base * 1.2;} }
    @include media-breakpoint-up(xxl){ .section-img{ margin-bottom: 0; } .title {font-size: $font-size-base * 1.6;} .sub-title {font-size: $font-size-base * 1.2;} }
  }

  &-card-masonry {
    padding: $font-size-base * 4 0;

    &-content {
      margin: $font-size-base * 1.2 0;
      box-shadow: 0 8px 16px 0 rgb($dark, 0.25);
      padding: $font-size-base * 3 $font-size-base * 3;
      border-radius: $border-radius-lg * 5;
      position: relative;
      max-width: auto;
      @include transition(all 0.5s ease);

      &.child {
        margin-top: $font-size-base ;
        
      }

      &:hover {
        transform: translateY(-5px);
        @include transition(all 0.5s ease);
      }

      .title {
        margin: 0;
        font-size: $font-size-base * 1.8;
        margin-bottom: $font-size-base * 2.5;
        line-height: 1.1; 
        font-weight: 700;
      }

      .sub-title {
        margin-bottom: $font-size-base * 0.5;
        font-size: $font-size-base ;
        // font-weight: 300;
        max-width: 75%;
        
      }

      @include media-breakpoint-up(sm) {max-width: 96%;margin: $font-size-base * 2 auto; &.child{margin-top: $font-size-base * 2;}  .title {font-size: $font-size-base * 2.6;} .sub-title {font-size: $font-size-base * 1.2; max-width: 60%;} }
      @include media-breakpoint-up(md) {max-width: 96%;margin: $font-size-base * 2 auto; &.child{margin-top: $font-size-base * 2;}  .title {font-size: $font-size-base * 2.6;} .sub-title {font-size: $font-size-base * 1.2; max-width: 60%;} }
      @include media-breakpoint-up(lg) {max-width: 96%;margin: $font-size-base * 2 auto; &.child{margin-top: $font-size-base * 2;}  .title {font-size: $font-size-base * 2.6;} .sub-title {font-size: $font-size-base * 1.2; max-width: 60%;} }
      @include media-breakpoint-up(xl) {max-width: 96%;margin: $font-size-base * 2 auto; &.child{margin-top: $font-size-base * 10;} .title {font-size: $font-size-base * 2.6;} .sub-title {font-size: $font-size-base * 1.2; max-width: 60%;} }
      @include media-breakpoint-up(xxl){max-width: 96%;margin: $font-size-base * 2.5 auto; &.child{margin-top: $font-size-base * 10;} .title {font-size: $font-size-base * 2.6;} .sub-title {font-size: $font-size-base * 1.2; max-width: 60%;} }
    }

    &-img {
      position: absolute;
      bottom: $font-size-base*1.5;
      right: $font-size-base*1.5;
      z-index: 2;
      max-width: 160px;

      img,
      svg {
        display: block;
        width: 100%;
      }
    }
  }

  &-card-secw {
    // padding: $font-size-base * 2;
    margin: $font-size-base *1.8 auto;

    // margin: $font-size-base * 1.5 0;
    // box-shadow: 0 8px 16px 0 rgb($dark, 0.25);
    // padding: $font-size-base * 3 $font-size-base * 3;
    border-radius: $border-radius-lg * 5;
    position: relative;
    max-width: 96%;
    @include transition(all 0.5s ease);
   

    &.child {
      margin-top: $font-size-base * 10;
    }

    &:hover {
      // transform: translateY(-10px);
      // @include transition(all 0.5s ease);
      // box-shadow: 0 10px 20px 0 rgb($dark, 0.55);
    }

    .numb {
      margin: 0;
      font-size: $font-size-base * 4;
      margin-bottom: $font-size-base * .5;
      line-height: 1.1; 
      font-weight: 800;
    }
    .title {
      margin: 0;
      font-size: $font-size-base * 1.4;
      margin-bottom: $font-size-base * 2.5;
      line-height: 1.1; 
      // font-weight: 700;
    }

    .sub-title {
      margin-bottom: $font-size-base * 0.5;
      font-size: $font-size-base * 1.4;
      // font-weight: 300;
      // max-width: 75%;
      
    }

    &-img {
      position: absolute;
      bottom: $font-size-base*1.5;
      right: $font-size-base*1.5;
      z-index: 2;
      max-width: 160px;

      img,
      svg {
        display: block;
        width: 100%;
      }
    }
  }

  &-main-content {
    // max-width: 80%;
    margin: 0 auto;

    .sub-title {
      margin-bottom: $font-size-base * 0.5;

      font-weight: 700;
    }

    .para {
      font-size: $font-size-base * 1.1;
    }

    .section-touch-link {
      margin-top: $font-size-base * 4;
    }
  }

  &-in-touch {
    min-height: 60vh;
    padding: $font-size-base * 4 0;
  }

  &-content {
    // max-width: 400px;
    padding: $font-size-base $font-size-base * 4;
  }

 
}

.section-heading {
  .header {
    margin: 0;
    font-size: $font-size-base * 2.8;
    // margin-bottom: $font-size-base * 2.5;
    line-height: 1.1;
    font-weight: 700;

    &-blockquote {
      font-size: $font-size-base * 1.5;
      margin: $font-size-base * 3.5 auto;
      
    }
  }

  .sub-header {
    font-size: $font-size-base * 1.2;
    margin: 0;
  }

  margin-bottom: $font-size-base * 2.5;
}

.list {
  &-data {
    padding: 0;
    margin: $font-size-base * 2.5 auto;
    font-size: 80%;

  }

  &-ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      display: block;
      margin-bottom: $font-size-base;

      .head {
        font-weight: 500;
        color: $black;
        font-size: 80%;
        &-sub {
          display: block;
        }
      }
    }
  }
}
