&-speical-offers {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: $border-radius-lg *3;

  .card-icon {
    cursor: pointer;

    img {
      @include transition(all 0.3s ease);
      display: block;
      width: 100%;
      filter: grayscale(1);
    }
  }

  &:hover {
    .card-icon {
      img {
        @include transition(all 0.3s ease);
        filter: grayscale(0);
      }
    }
  }

  .title-text {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    // 
    line-height: 1;
    @include gradient-y-three-colors($start-color: rgba($black, 0), $mid-color: rgba($gray-800, .9), $color-stop: 50%, $end-color: rgba($gray-800, .9));
    padding: $font-size-base $font-size-base;

    .title {
      color: $white;
      font-size: 20px;
      margin-bottom: $font-size-base * 1.2;
    }

    .point {
      color: $white;
      font-size: 80%;

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        list-style: none;
        position: relative;
        margin-bottom: $font-size-base*.1;
        padding-left: $font-size-base;

        &:after {
          content: "";
          position: absolute;
          background-color: $primary;
          top: $font-size-base*.1;
          left: 0;
          width: $font-size-base*.5 ;
          height: $font-size-base*.5 ;
        }
      }

    }

    .book-now {
      margin-top: $font-size-base * 1.4;
      color: $white;
      font-size: 20px;

      .rstag {
        font-size: 80%;

      }
    }
  }
  
  
}
