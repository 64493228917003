&-working-with {
    min-height: 50vh;
    display: block;
    padding: $font-size-base * 4 0;
    background-color: rgba($black, 0.02);
    @include media-breakpoint-up(sm) { min-height: 50vh;  padding: $font-size-base * 4 0;}
    @include media-breakpoint-up(md) { min-height: 50vh;  padding: $font-size-base * 4 0;}
    @include media-breakpoint-up(lg) { min-height: 50vh;  padding: $font-size-base * 4 0;}
    @include media-breakpoint-up(xl) { min-height: 50vh;  padding: $font-size-base * 4 0;}
    @include media-breakpoint-up(xxl){ min-height: 50vh;  padding: $font-size-base * 4 0;}
  }
