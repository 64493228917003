&-packages {
  &-horizontal {
    display: block;
    position: relative;
    overflow: hidden;
    // padding: $font-size-base * 3.5 0;
    border-radius: $border-radius;
    margin: $font-size-base * .8 auto;
    border: 1px solid $default-border;
    @include transition(all 0.3s ease-in-out);
    display: block;
    

    @include media-breakpoint-up(lg) {display: -webkit-box; display: -ms-flexbox; display: flex;}
    @include media-breakpoint-up(xl) {display: -webkit-box; display: -ms-flexbox; display: flex;}
    @include media-breakpoint-up(xxl){display: -webkit-box; display: -ms-flexbox; display: flex;}

    &-icon {
      width: 100%;
      cursor: pointer;

      @include media-breakpoint-up(lg) {width: 27%; }
      @include media-breakpoint-up(xl) {width: 27%; }
      @include media-breakpoint-up(xxl){width: 27%; }

      img {
        @include transition(all 0.3s ease);
        display: block;
        width: 100%;
        filter: grayscale(0);
      }

    }

    &.even {
      .card-icon {
        img {
          @include transition(all 0.3s ease);
          filter: grayscale(1);
        }
      }
    }

    &-inner {
      width: 100%;
      padding: $font-size-base ;
      // padding-bottom: 0;

      @include media-breakpoint-up(lg) {padding: $font-size-base $font-size-base * 2;}
      @include media-breakpoint-up(xl) {padding: $font-size-base $font-size-base * 2;}
      @include media-breakpoint-up(xxl){padding: $font-size-base $font-size-base * 2;}

      .packages-content {
        &-right {
          padding-left: $font-size-base * 2;
          max-width: 220px;
        }
        // &-left {
        // margin-bottom: $font-size-base * 2;
        // @include media-breakpoint-up(lg) {margin-bottom: 0;}
        // @include media-breakpoint-up(xl) {margin-bottom: 0;}
        // @include media-breakpoint-up(xxl){margin-bottom: 0;}
        // }
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
        }
      }

      .tour {
        &-title {
          margin-bottom: $font-size-base * 1.5;

          .duration {
            font-weight: 600;
            color: $gray-600;
          }

          .content {
            margin: 0;
            // @include text-ellipsis();
            // white-space: nowrap;
            // -webkit-box-flex: 1;
            // flex-grow: 1;
            // flex: 1;
          }
        }

        &-duration {
          margin-bottom: $font-size-base;

          .title {
            font-size: 120%;
            font-weight: 600;
          }

          .content {
            margin: 0;
            @include text-ellipsis();
            white-space: nowrap;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex: 1;
          }
        }

        &-destinations {
          margin-bottom: $font-size-base*1.3;

          .title {
            font-size: 120%;
            font-weight: 600;
          }

          .content {
            margin: 0;
            @include text-ellipsis();
            white-space: nowrap;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex: 1;
          }

          .list {
            li {
              color: $primary;
              font-weight: 600;
              position: relative;

              &::after {
                margin-left: $font-size-base*.6;
                margin-right: $font-size-base*.6;
                content: '';
                display: inline-flex;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('../images/arrow-right-solid.svg');
                background-size: $font-size-base $font-size-base;
                width: $font-size-base;
                height: $font-size-base*.8;
                color: $primary;
                text-align: center;
              }

              &:last-child {
                &::after {
                  display: none;
                }
              }
            }
          }
        }

        &-on-request {
          margin-bottom: $font-size-base*1.4;

          .title {
            font-size: 120%;
            font-weight: 600;
            margin-bottom: $font-size-base*.5;
          }

          .ico-svg {
            display: block;
            margin-bottom: $font-size-base*.5;

            img,
            .svg {
              margin: 0 auto;
              width: $font-size-base*2.2;
              display: block;
            }
          }

          .list {
            li {
              text-align: center;
              padding: 0 $font-size-base;
              font-weight: 600;
              color: $primary;
              position: relative;
              width: 33.33%;
              font-size: 80%;

              &::after {
                position: absolute;
                top: $font-size-base*.4;
                bottom: $font-size-base*.4;
                width: 2px;
                background-color: darken($border-color, 20%);
                right: 0;
                content: "";
              }

              &:first-child {
                padding-left: 0;
              }
              &:nth-child(4){
                padding-left: 0;
              }

              &:last-child {
                padding-right: auto;

                &::after {
                  display: none;
                }
              }

              @include media-breakpoint-up(lg) {
                width: auto;
              font-size: 100%;
              padding: 0 $font-size-base*2;
              &:nth-child(4){
                padding-left: $font-size-base*2;
              }
                &:first-child {
                  padding-left: 0;
                }
                

                &:last-child {
                  padding-right: 0;

                  &::after {
                    display: none;
                  }
                }
              }

              @include media-breakpoint-up(xl) {
                width: auto;
              font-size: 100%;
              padding: 0 $font-size-base*2;
              &:nth-child(4){
                padding-left: $font-size-base*2;
              }
                &:first-child {
                  padding-left: 0;
                }

                &:last-child {
                  padding-right: 0;

                  &::after {
                    display: none;
                  }
                }
              }

              @include media-breakpoint-up(xxl) {
                width: auto;
              font-size: 100%;
              padding: 0 $font-size-base*2;
              &:nth-child(4){
                padding-left: $font-size-base*2;
              }
                &:first-child {
                  padding-left: 0;
                }

                &:last-child {
                  padding-right: 0;

                  &::after {
                    display: none;
                  }
                }
              }
            }
          }

          .content {
            margin: 0;
            @include text-ellipsis();
            white-space: nowrap;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex: 1;
          }
        }

        &-link {
          margin-right: $font-size-base;
          .btn{font-size: 80%;
@include media-breakpoint-up(lg) {font-size: $font-size-base;}
@include media-breakpoint-up(xl) {font-size: $font-size-base;}
@include media-breakpoint-up(xxl){font-size: $font-size-base;}
          }

          .content {
            margin: 0;
            @include text-ellipsis();
            white-space: nowrap;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex: 1;
          }
        }
      }
    }
    
  }
}
