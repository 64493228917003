// .modal-content {
// 	border: 0;
// 	@include z-depth-large;
// 	border-radius: $border-radius-large + 6;
// }

// .modal-jumbotron {
// 	padding: $padding-base-horizontal $padding-base-vertical;
// 	margin: 0;
// 	h1 {
// 		font-size: 300%;
// 	}
// }

// .modal-dialog.dialog-full {
// 	margin: 0 auto;
// 	width: auto;
// 	border-radius: 0;

// 	.modalpop-forms {
// 		//@include z-depth-large;
// 		background-color: $brand-white;
// 		border-radius: 0;
// 	}
// }

.modal-header-terms {
	position: relative;
	// text-align: center;
	padding: $font-size-base * 2.5;
	padding-right: $font-size-base * 4;
	padding-bottom: 0;
	.btn-close {
		padding: $font-size-base * 1.5;
		right: 0;
		top: 0;
		z-index: 2;
		position: absolute;
	}
	.modal-title {
	}

	.btn-close {
	}
	.title {
		font-size: $font-size-base * 0.8;
		font-weight: 600;
	}
}

.modal-footer {
	@include clearfix;
	// .add-rooms{
	// 	float: right;
	// }
	// .add-rooms{
	// 	float: right;
	// }
}

.modal-dialog {
	&.modal-padding {
		.modal-body {
			padding: $font-size-base * 2 $font-size-base * 2.5;
			padding-bottom: $font-size-base;
		}
		.modal-footer {
			padding: $font-size-base * 2.5;
			padding-bottom: $font-size-base * 2;
			padding-top: 0;
			border: 0;
		}
	}

	&.modal-upload {
		.modal-body {
			padding: $font-size-base $font-size-base * 2.5;
			padding-bottom: $font-size-base * 3;
		}
		.modal-footer {
			padding: $font-size-base * 2.5;
			padding-bottom: $font-size-base * 2;
			padding-top: 0;
			border: 0;
		}
	}
	.section-plans {
		// padding: 0;
		overflow-x: auto;
		padding-top: $font-size-base;

		// padding: $font-size-base * 2 $font-size-base * 2.5;
	}
	.modal-plans-body {
		padding-left: $font-size-base * 2.5;
		margin-bottom: $font-size-base;
	}
}
