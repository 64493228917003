&-tour-packages {
  display: block;
  position: relative;
  overflow: hidden;
  // padding: $font-size-base * 3.5 0;
  border-radius: $border-radius-lg *3;
  margin: $font-size-base * .8 auto;
  border: 3px solid $gray-500;
  @include transition(all 0.3s ease-in-out);

  .card-icon {
    cursor: pointer;
    img {
      @include transition(all 0.3s ease-in-out);
      display: block;
      width: 100%;
      transform: scale(1); 
    }
  }

  &.even {
    .card-icon {
      img {
        @include transition(all 0.3s ease-in-out);
        // filter: grayscale(1);
        transform: scale(1);
      }
    }
  }

  &:hover {
    .card-icon {
      img {
        @include transition(all 0.3s ease-in-out);
        // filter: grayscale(0);
        transform: scale(1.2);
      }
    }
    border-color: $primary;
  }

  .title-text {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    // 
    line-height: 1;
    @include gradient-y-three-colors($start-color: rgba($primary, 0), $mid-color: rgba($primary, .9), $color-stop: 40%, $end-color: rgba($primary, .9));
    padding: $font-size-base*1.8 $font-size-base;
    padding-bottom: $font-size-base*1.2;


    .title {
      color: $white;
      font-size: 20px;
    }

    .days {
      font-weight: 700;
      font-size: 80%;
      margin-bottom: $font-size-base * .5;
    }

    .text {
      margin: 0;
      @include text-ellipsis();
      white-space: nowrap;
      -webkit-box-flex: 1;
      flex-grow: 1;
      flex: 1;
    }

    .book-now {
      margin-top: $font-size-base * 1.4;
      color: $white;
      font-size: 20px;

      .rstag {
        font-size: 80%;

      }
    }
  }


}
