//
// booking card form
// --------------------------------------------------

@each $color, $value in $theme-colors {
	.btn-borderd-#{$color} {
		@include button-borderd-variant($color:$value, $active-background:transparent); 
	}
}

// Alternate buttons
// --------------------------------------------------

// .btn-borderd-theme{@include button-borderd-variant ($btn-theme-border); }
// .btn-borderd-secondary{@include button-borderd-variant ($btn-secondary-border); }
// .btn-borderd-alernative{@include button-borderd-variant ($btn-alernative-border); }
// .btn-borderd-default{@include button-borderd-variant ($btn-default-border); }
// .btn-borderd-primary{@include button-borderd-variant ($btn-primary-border); }
// .btn-borderd-success{@include button-borderd-variant ($btn-success-border); }
// .btn-borderd-info{@include button-borderd-variant ($btn-info-border); }
// .btn-borderd-warning{@include button-borderd-variant ($btn-warning-border); }
// .btn-borderd-danger{@include button-borderd-variant ($btn-danger-border); }
// .btn-borderd-deep-orange{@include button-borderd-variant ($btn-deep-orange-border); }
// .btn-borderd-yellow{@include button-borderd-variant ($btn-yellow-border); }
