&-page-banner {
  position: relative;
  overflow: hidden;
  position: relative;
  min-height: 80vh;
  z-index: 2;

  .page-banner {
    min-height: 90vh;
    // overflow: hidden;
    width: 100%!important;
    background-size: cover;
    // padding: $font-size-base * 2 0;
}

  .title {
    margin-bottom: $font-size-base * 0.5;
    font-weight: 700;
    margin-bottom: $font-size-base * 2;
    font-size: $font-size-base * 2; 

    span { 
      font-weight: 700;
      // text-transform: uppercase;
    }

    @include media-breakpoint-up(md) {font-size: $font-size-base * 2.7;}
    @include media-breakpoint-up(lg) {font-size: $font-size-base * 2.7;}
    @include media-breakpoint-up(xl) {font-size: $font-size-base * 2.2;}
    @include media-breakpoint-up(xxl){font-size: $font-size-base * 2.9;}
  }

  .sub-title {
    font-size: $font-size-base * 1.1;
    
  }

  .page-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -4;
  }

  &-img {
    overflow: hidden;
    object-position: center;
    position: absolute;
    // top: 50%;
    bottom: 0;
    right: 0;
    // left: 0;
    z-index: -1;
    object-position: bottom;

    &.mini {
      max-width: 85%; right: -22%;

      @include media-breakpoint-up(lg) {max-width: 49%; right: 3%;  .title {font-size: $font-size-base * 2.7;}}
      @include media-breakpoint-up(xl) {max-width: 49%; right: 3%;  .title {font-size: $font-size-base * 2.7;}}
      @include media-breakpoint-up(xxl){max-width: 49%; right: 3%;  .title {font-size: $font-size-base * 2.7;}}
    }

    &.squire {
      max-width: 70%;
      right: -15%;
      bottom: 0;
      @include media-breakpoint-up(lg) {max-width: 100%; right: 10%; bottom: 0%; width: 30%; .title {font-size: $font-size-base * 2.7;}}
      @include media-breakpoint-up(xl) {max-width: 100%; right: 10%; bottom: 0%; width: 26%; .title {font-size: $font-size-base * 2.7;}}
      @include media-breakpoint-up(xxl){max-width: 100%; right: 10%; bottom: 0%; width: 26%; .title {font-size: $font-size-base * 2.7;}}
    }

    &.topimg {
      width: 130%;
      max-width: 130%;
      right: -43%; 
      bottom: 0%;
      @include media-breakpoint-up(lg) {max-width: 100%; right: 0%; bottom: 25%; width: 30%; .title {font-size: $font-size-base * 2.7;}}
      @include media-breakpoint-up(xl) {max-width: 100%; right: 0%; bottom: 25%; width: 30%; .title {font-size: $font-size-base * 2.7;}}
      @include media-breakpoint-up(xxl){max-width: 100%; right: 0%; bottom: 18%; width: 45%; .title {font-size: $font-size-base * 2.7;}}
    }

    img {
      display: block;
      width: 100%;
      object-fit: fill;
    }
  }

  .hero { 
    padding: $font-size-base * 2 0;
    min-height: 80vh;
    // position: absolute;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba($black,.3);

    @include media-breakpoint-up(md) {
        position: absolute;
        padding: $font-size-base * 2 0;
      }
    
      @include media-breakpoint-up(lg) {
        position: absolute;
        padding: $font-size-base * 2 0;
      }
    
      @include media-breakpoint-up(xl) {
        position: absolute;
        padding: $font-size-base * 2 0;
      }
    
      @include media-breakpoint-up(xxl) {
        position: absolute;
        padding: $font-size-base * 2 0;
      }

    .title {
      font-size: $font-size-base * 2.5;
      line-height: 1.1;
      margin-bottom: .4rem;
      font-weight: 700;
      text-shadow: .1em .1em 0 hsl(200 50% 30%);

      &-btns{
        margin-top: $font-size-base * 1.6;
        @include media-breakpoint-up(md) {
            margin-top: $font-size-base * 4;
          }

      }

      @include media-breakpoint-up(md) {font-size: $font-size-base * 4.4; margin-bottom: 2.4rem;}
      @include media-breakpoint-up(lg) {font-size: $font-size-base * 4.4; margin-bottom: 2.4rem;}
      @include media-breakpoint-up(xl) {font-size: $font-size-base * 4.4; margin-bottom: 2.4rem;}
      @include media-breakpoint-up(xxl){font-size: $font-size-base * 4.4; margin-bottom: 2.4rem;}
    }

    .sub-title {
      line-height: 1.2;
      margin-bottom: $font-size-base * 0.7;
      font-size: $font-size-base*1.4;
      text-shadow: .05em .05em 0 hsl(200 50% 30%);
      
      @include media-breakpoint-up(md) {font-size: $font-size-base * 2.2;}
      @include media-breakpoint-up(lg) {font-size: $font-size-base * 2.2;}
      @include media-breakpoint-up(xl) {font-size: $font-size-base * 2.2;}
      @include media-breakpoint-up(xxl){font-size: $font-size-base * 2.2;}
    }
    
  }
  .activity-navigation {
    position: absolute;
    bottom: $font-size-base * 2;
    right: $font-size-base * 2;
    z-index: 3;
    .card-slider {
      &-btn{
        border: 1px solid rgba($white,.7);
        background-color: rgba($white,.7); 
        border-radius: $border-radius;
        @include transition(all 0.3s ease);
        &:hover,&:focus{
          background-color: rgba($white,1); 
          @include transition(all 0.3s ease);
        }
      }
      &-prev{
        right: 0;
        transform: rotate(180deg);
        margin-right: 4px;
      }
      &-next{
        right: 0;
      }
    }
    .title-icons {
      padding: $font-size-base * .5 $font-size-base;
      
      img,.svg, svg{
        display: block;
        height: $font-size-base*.8; 
      }
    }
  }

  
  @include media-breakpoint-up(xxl) {
    min-height: 80vh;
  }
}
