.section {
  @import 'section/our-services';
  @import 'section/working-with';
  @import 'section/about-us';
  @import 'section/banner';
  @import 'section/get-free-quote';
  @import 'section/parallax';
}

.artical-title {
  position: relative;

  // padding-top:  $font-size-base * 2.4;
  .large {
    font-size: $font-size-base * 2.4;
    font-weight: 600;
  }

  .secondary-title {
    position: absolute;
    display: block;
    color: transparent;
    line-height: 0.35;
    background-color: $white;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: lighten($brand-alernative, 8%);
    // @include opacity(.5);
    left: 0;


    &:first-child {
      top: -$font-size-base * 3;
      overflow: hidden;
      line-height: 1;
      height: $font-size-base * 2.4;
    }

    &:nth-child(2) {
      top: -$font-size-base;
      overflow: hidden;
    }
  }

  .title {
    margin-bottom: $font-size-base*2;
    z-index: 2;
    position: relative;
    line-height: 0.5;
    background-color: #fff;
    transform-origin: 667px 35px;
    transform: translate(0px, 0px);
  }

  @include media-breakpoint-up(sm) {
    .large {
      font-size: $font-size-base * 7.5;
    }

    .secondary-title {
      -webkit-text-stroke-width: 2px;
      line-height: 0.35;

      &:first-child {
        top: -$font-size-base * 5;
        height: $font-size-base * 7.5;
      }

      &:nth-child(2) {
        top: -$font-size-base * 2.4;
      }
    }

    .title {
      transform-origin: 667px 35px;
      margin-bottom: $font-size-base * 4;
    }
  }

  @include media-breakpoint-up(md) {
    .large {
      font-size: $font-size-base * 4.7;
    }

    .secondary-title {
      -webkit-text-stroke-width: 2px;
      line-height: 0.30;

      &:first-child {
        top: -$font-size-base * 5;
        height: $font-size-base * 4.7;
      }

      &:nth-child(2) {
        top: -$font-size-base * 3;
      }
    }

    .title {
      transform-origin: 667px 35px;
      margin-bottom: $font-size-base * 4;
    }
  }

  @include media-breakpoint-up(lg) {
    .large {
      font-size: $font-size-base * 5.5;
    }

    .secondary-title {
      -webkit-text-stroke-width: 2px;
      line-height: 0.3;

      &:first-child {
        top: -$font-size-base * 5.8;
        height: $font-size-base * 5.5;
      }

      &:nth-child(2) {
        top: -$font-size-base * 1.6;
      }
    }

    .title {
      transform-origin: 667px 35px;
      margin-bottom: $font-size-base * 4;
    }
  }

  @include media-breakpoint-up(xl) {
    .large {
      font-size: $font-size-base * 5.5;
    }

    .secondary-title {
      -webkit-text-stroke-width: 2px;
      line-height: 0.3;

      &:first-child {
        top: -$font-size-base * 5.8;
        height: $font-size-base * 5.5;
      }

      &:nth-child(2) {
        top: -$font-size-base * 1.6;
      }
    }

    .title {
      transform-origin: 667px 35px;
      margin-bottom: $font-size-base * 4;
    }
  }

  @include media-breakpoint-up(xxl) {
    .large {
      font-size: $font-size-base * 5.5;
    }

    .secondary-title {
      -webkit-text-stroke-width: 2px;
      line-height: 0.3;

      &:first-child {
        top: -$font-size-base * 5.8;
        height: $font-size-base * 5.5;
      }

      &:nth-child(2) {
        top: -$font-size-base * 1.6;
      }
    }

    .title {
      transform-origin: 667px 35px;
      margin-bottom: $font-size-base * 4.5;
    }
  }

}

.description {
  position: relative;

  &-form {
    padding: $font-size-base *1.8 $font-size-base * 2.5;
    border-radius: $border-radius*2;
    margin-bottom: $font-size-base *2;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xxl) {
      margin-bottom: 0;
    }
  }
}

.tags-card {
  margin-top: $font-size-base * 1.2;

  .heading {
    font-weight: 800;
    font-size: $font-size-base * 2.2;
    line-height: 1;
    margin-bottom: $font-size-base * 1.5;

    span {
      display: block;
      font-weight: 400;
    }
  }

  .sub-heading {
    font-size: $font-size-base;
    font-weight: 400;
    line-height: 1.5;
    // margin-top: $font-size-base * 2;
  }

  .tags-list {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: inline-block;
      margin: $font-size-base * 0.3 auto;
      // margin-right: $font-size-base * 0.5;

      a {
        text-decoration: none;
        color: $brand-alernative;
        display: block;
        font-weight: 500;
        font-size: $font-size-base * .9;
        padding: $font-size-base * 0.45 $font-size-base * 1.4;
        position: relative;
        border: 1px solid lighten($primary, 25%);
        margin-right: $font-size-base * 0.2;
        border-radius: $border-radius;
        @include transition(all 0.5s ease);

        &:hover {
          color: $gray-900;
          border-color: $primary;
          background-color: lighten($primary, 45%);
          @include transition(all 0.5s ease);
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    margin-top: $font-size-base * 1.5;

    .heading {
      font-size: $font-size-base * 3.2;
    }

    .tags-list {
      li {
        margin: $font-size-base * 0.5 auto;

        a {
          font-size: $font-size-base;
          padding: $font-size-base * 0.7 $font-size-base * 2.5;
          margin-right: $font-size-base * 0.4;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: $font-size-base * 1.5;

    .heading {
      font-size: $font-size-base * 3.2;
    }

    .tags-list {
      li {
        margin: $font-size-base * 0.5 auto;

        a {
          font-size: $font-size-base;
          padding: $font-size-base * 0.7 $font-size-base * 2.5;
          margin-right: $font-size-base * 0.4;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: $font-size-base * 1.5;

    .heading {
      font-size: $font-size-base * 3.2;
    }

    .tags-list {
      li {
        margin: $font-size-base * 0.5 auto;

        a {
          font-size: $font-size-base;
          padding: $font-size-base * 0.7 $font-size-base * 2.5;
          margin-right: $font-size-base * 0.4;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-top: $font-size-base * 1.5;

    .heading {
      font-size: $font-size-base * 2.4;
    }

    .tags-list {
      li {
        margin: $font-size-base * 0.5 auto;

        a {
          font-size: $font-size-base;
          padding: $font-size-base * 0.6 $font-size-base * 2.5;
          margin-right: $font-size-base * 0.4;
        }
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    margin-top: $font-size-base * 1.5;

    .heading {
      font-size: $font-size-base * 3;
    }

    .tags-list {
      li {
        margin: $font-size-base * 0.5 auto;

        a {
          font-size: $font-size-base * 1.15;
          padding: $font-size-base * 0.6 $font-size-base * 2.5;
          margin-right: $font-size-base * 0.4;
        }
      }
    }
  }
}