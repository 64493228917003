.founders {
  &-card {
    // max-width: 80%;
    margin: 0 auto;
    margin: $font-size-base * 1.35 auto;

    .img-full {
      display: block;
      width: 100%;
    }

    border: 4px solid $border-colors;
    border-radius: $border-radius-lg *6;
    padding: $font-size-base *3;
    margin-bottom: $font-size-base *6;
  }

  &-media {
    margin: $font-size-base * 1.35 auto;

    .head {
      font-size: $font-size-base*1.2;
      font-weight: 600;
    }

    .user {
      font-size: $font-size-base * .8;
    }

    .date {
      font-size: $font-size-base * .7;
    }
  }
}



.list {
  &-data {
    padding: 0;
    margin: $font-size-base * 2.5 auto;
    font-size: 80%;

  }

  &-ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      display: block;
      margin-bottom: $font-size-base;

      .head {
        font-weight: 500;
        color: $black;
        font-size: 80%;

        &-sub {
          display: block;
        }
      }
    }
  }

  &-base {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      display: block;
      position: relative;
      padding-left: $font-size-base*1.2;
      margin-bottom: $font-size-base*.3;

      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        width: $font-size-base*.5;
        height: $font-size-base*.5;
        background-color: $brand-default;
        top: $font-size-base*.5;
      }

      // margin-bottom: $font-size-base;

      .head {
        font-weight: 500;
        color: $black;
        font-size: 80%;

        &-sub {
          display: block;
        }
      }
    }
  }
}


.img {
  &-full {
    display: block;
    width: 100%;
  }
}
