.home-page {
    overflow: hidden;
    // background-image:
    //     url("/images/bg-1.jpg"),
    //     url("/images/bg-2.jpg"),
    //     url("/images/bg-3.jpg");
    // // background-position:
    // //     130% 24%,
    // //     -60% 40%, 
    // //     160% 51%;
    // background-repeat: no-repeat;
    // // background-size: 65% auto;



    // @include media-breakpoint-up(sm) {
    //     // font-size: $font-size-base * 5;
    //     background-position:
    //         right 18%,
    //         left 58%,
    //         right 70%;
    //     background-size: auto;
    // }

    // @include media-breakpoint-up(md) {

    //     // font-size: $font-size-base * 5;
    //     background-position:
    //         right 18%,
    //         left 43%,
    //         right 74%;
    //     background-size: auto;
    // }

    // @include media-breakpoint-up(lg) {
    //     // font-size: $font-size-base * 5;
    //     background-position:
    //         right 14%,
    //         left 42%,
    //         right 74%;
    //     background-size: auto;
    // }

    // @include media-breakpoint-up(xl) {
    //     // font-size: $font-size-base * 5;
    //     background-position:
    //         right 15%,
    //         left 45.5%,
    //         right 80%;
    //     background-size: auto;
    // }

    // @include media-breakpoint-up(xxl) {
    //     // font-size: $font-size-base * 5;
    //     @media screen and (min-width: 992px) {
    //         background-position:
    //             right 18%,
    //             left 58%,
    //             right 70%;
    //         background-size: auto;
    //     }
    // }


    // .section {
    //     padding-bottom: 8rem;
    //     position: relative;

    //     @media screen and (min-width: 992px) {
    //         padding-bottom: 12rem;
    //     }

    //     @media screen and (min-width: 1200px) {
    //         padding-bottom: 16rem;
    //     }

    //     h2 {
    //         font-size: 2.8rem;
    //         line-height: 1.4;
    //         margin-bottom: 1.5rem;

    //         @media screen and (min-width: 768px) {
    //             font-size: 3.2rem;
    //             margin-bottom: 2.5rem;
    //         }

    //         @media screen and (min-width: 992px) {
    //             font-size: 3.5rem;
    //         }

    //         @media screen and (min-width: 1200px) {
    //             font-size: 4.5rem;
    //             margin-bottom: 3.5rem;
    //         }
    //     }

    //     p {
    //         margin: 0;

    //         &.chapo {
    //             &+.cta {
    //                 margin-top: 4rem;
    //             }
    //         }
    //     }

    //     // METHODE
    //     &.methode {
    //         @media screen and (max-width: 500px) {
    //             padding-bottom: 5rem;
    //         }

    //         .steps {
    //             margin-top: 7rem;
    //             position: relative;

    //             @media screen and (min-width: 992px) {
    //                 display: flex;
    //                 align-items: center;
    //                 height: 55rem;
    //                 margin-top: 4rem;
    //             }

    //             @media screen and (min-width: 1200px) {
    //                 height: 60rem;
    //             }

    //             .numbers {
    //                 display: flex;
    //                 justify-content: space-between;
    //                 font-weight: 400;
    //                 font-size: 1.2rem;
    //                 position: relative;
    //                 z-index: 10;

    //                 @media screen and (min-width: 992px) {
    //                     flex-direction: column;
    //                     height: 37rem;
    //                     margin-right: 4rem;
    //                 }

    //                 @media screen and (min-width: 1200px) {
    //                     margin-right: 7rem;
    //                 }

    //                 .progressline {
    //                     border-bottom: 1px dashed #C4C4C4;
    //                     position: absolute;
    //                     top: 1.2rem;
    //                     left: 2.6rem;
    //                     width: calc(100% - 2.6rem);
    //                     height: 1px;

    //                     .line {
    //                         background: #FF8271;
    //                         height: 1px;
    //                     }

    //                     @media screen and (min-width: 992px) {
    //                         border-bottom: none;
    //                         border-right: 1px dashed #C4C4C4;
    //                         left: 1.2rem;
    //                         top: 2.6rem;
    //                         height: calc(100% - 2.6rem);
    //                         width: 1px;

    //                         .line {
    //                             width: 1px;
    //                         }
    //                     }
    //                 }

    //                 span {
    //                     background: white;
    //                     border: 1px solid #C4C4C4;
    //                     border-radius: 50%;
    //                     cursor: pointer;
    //                     display: flex;
    //                     align-items: center;
    //                     justify-content: center;
    //                     height: 2.6rem;
    //                     width: 2.6rem;
    //                     position: relative;

    //                     &.active {
    //                         border-color: #FF8271;
    //                     }
    //                 }
    //             }

    //             .stepcontainer{
    //                 z-index: 50;
    //             }

    //             .step {
    //                 position: absolute;
    //                 top: 3rem;
    //                 padding-top: 3.5rem;
    //                 z-index: 10;

    //                 &.active {
    //                     display: block;
    //                 }

    //                 @media screen and (min-width: 768px) {
    //                     top: 4rem;
    //                 }

    //                 @media screen and (min-width: 992px) {
    //                     top: 25%;
    //                     padding: 0;
    //                     width: 30rem;
    //                 }

    //                 @media screen and (min-width: 1200px) {
    //                     top: 30%;
    //                     width: 40rem;
    //                 }

    //                 .num {
    //                     display: block;
    //                     font-size: 5rem;
    //                     font-weight: 300;
    //                     line-height: 1;
    //                     margin-bottom: 1rem;

    //                     @media screen and (min-width: 768px) {
    //                         font-size: 5.5rem;
    //                         margin-bottom: 2rem;
    //                     }

    //                     @media screen and (min-width: 992px) {
    //                         font-size: 6rem;
    //                     }

    //                     @media screen and (min-width: 1200px) {
    //                         font-size: 7rem;
    //                         margin-bottom: 3rem;
    //                     }
    //                 }

    //                 h3 {
    //                     font-size: 2rem;
    //                     font-weight: 500;
    //                     margin-bottom: 1.5rem;

    //                     @media screen and (min-width: 768px) {
    //                         font-size: 2.6rem;
    //                     }

    //                     @media screen and (min-width: 1200px) {
    //                         font-size: 2.8rem;
    //                     }
    //                 }
    //                 &#step1{
    //                     .num{
    //                         opacity: 1;
    //                     }
    //                     .stepcontent{
    //                         opacity: 1;
    //                     }
    //                 }

    //                 &#step5 {
    //                     @media screen and (min-width: 992px) {
    //                         color: white !important;
    //                     }
    //                 }
    //             }
    //         }

    //         .video-wrapper {
    //             height: 27rem;
    //             margin-top: 27rem;
    //             width: 100%;
    //             position: relative;
    //             z-index: 0;

    //             @media screen and (min-width: 768px) {
    //                 height: 30rem;
    //             }

    //             @media screen and (min-width: 992px) {
    //                 position: absolute;
    //                 right: 0;
    //                 top: 50%;
    //                 transform: translateY(-50%);
    //                 height: 55rem;
    //                 width: 100vw;
    //                 margin-left: -50vw;
    //                 margin-top: 0;
    //                 left: 50%;
    //                 overflow: hidden;
    //             }

    //             @media screen and (min-width: 1200px) {
    //                 height: 60rem;
    //             }

    //             .video {
    //                 position: absolute;
    //                 height: 27rem;
    //                 width: 100%;
    //                 object-fit: cover;

    //                 &.active {
    //                     display: block;
    //                 }

    //                 @media screen and (min-width: 768px) {
    //                     height: 30rem;
    //                     object-fit: contain;
    //                 }

    //                 @media screen and (min-width: 992px) {
    //                     height: 55rem;
    //                     width: 60rem;
    //                     left: 50%;
    //                     margin-left: auto;
    //                 }

    //                 @media screen and (min-width: 1200px) {
    //                     height: 60rem;
    //                     width: 65rem;
    //                 }

    //                 @media screen and (min-width: 1400px) {
    //                     width: 80rem;
    //                 }

    //                 &#video1 {
    //                     opacity: 1;
    //                 }

    //                 &#video5 {
    //                     @media screen and (min-width: 992px) {
    //                         height: 100%;
    //                         width: 100vw;
    //                         object-fit: cover;
    //                         left: 0;
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     // CAS CLIENTS
    //     .wp-block-revo-gutenberg-blocks-preview-casclient {
    //         &:nth-of-type(1) {
    //             margin-top: 6rem;

    //             @media screen and (min-width: 992px) {
    //                 margin-top: 7rem;
    //             }

    //             @media screen and (min-width: 1200px) {
    //                 margin-top: 8.5rem;
    //             }
    //         }

    //         &+.cta {
    //             margin-top: 1rem;
    //         }
    //     }

    //     &.insights {
    //         @media screen and (max-width: 991px) {
    //             padding-bottom: 6rem;
    //         }

    //         h2 {
    //             font-weight: 600;
    //             margin-bottom: 3rem;

    //             @media screen and (min-width: 992px) {
    //                 margin-bottom: 4.5rem;
    //             }

    //             @media screen and (min-width: 1200px) {
    //                 margin-bottom: 6rem;
    //             }
    //         }

    //         hr+.card {
    //             margin-top: 3.5rem;

    //             @media screen and (min-width: 992px) {
    //                 margin-top: 5.5rem;
    //             }
    //         }

    //         .cta {
    //             margin-top: 3.5rem;
    //             text-align: center;

    //             @media screen and (min-width: 992px) {
    //                 margin-top: 7rem;
    //             }
    //         }
    //     }
    // }
}